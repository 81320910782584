<template>
    <button class="dashboard-btn" v-on:click="setIsModalVisible(true)">Select Games</button>
    <ModalDashboard
        :isModalVisible="isModalVisible"
        :isModalVisibleEdit="setIsModalVisible"
        :saveFunction="saveNewRoulette"
    >
        <div class="flex flex-row flex-wrap gap-3 justify-center max-h overflow-auto">
            <div
                v-for="game in games"
                :key="game"
                class="rounded-lg p-3 w-5/12"
                :class="gameSelected(game.id) ? 'bg-green-300' : 'bg-red-300'"
                v-on:click="selectGame(game)"
            >
                <p class="text-black">{{ game.name_id }} -- {{ game.language }}</p>
            </div>
        </div>
    </ModalDashboard>
</template>

<script>
import ModalDashboard from "@/components/dashboard/ModalDashboard.vue";
import { useState } from "@/hooks/state";
import { onMounted, ref, watch } from "@vue/runtime-core";
import { axiosToken } from "@/libs/http";
import { useStore } from "vuex";

export default {
    components: {
        ModalDashboard,
    },
    setup() {
        const [isModalVisible, setIsModalVisible] = useState(false);
        const store = useStore();
        const games = ref();
        const gamesSelected = ref([]);
        onMounted(() => {
            getGames();
            gamesSelected.value = store.state.storeEventData.roulette_games;
        });
        watch(
            () => store.state.storeEventData.roulette_games,
            () => {
                gamesSelected.value = store.state.storeEventData.roulette_games;
            }
        );

        const getGames = async () => {
            const data = await axiosToken({
                url: "gamecontent/content_game/filtered",
                method: "get",
                authorization: true,
            });
            games.value = data.data;
        };

        const saveNewRoulette = async () => {
            let body_data = [];
            gamesSelected.value.forEach((e) => {
                body_data.push(e.id);
            });
            const dataEvent = await axiosToken({
                url: "gamemanager/gameRoulette/" + store.state.storeEventData.url,
                method: "post",
                authorization: true,
                body: {
                    games: body_data,
                },
            });
        };

        const gameSelected = (id) => {
            const selectedGame = gamesSelected.value.filter((e) => e.id == id);
            if (selectedGame.length > 0) {
                return true;
            }
            return false;
        };

        const selectGame = (game) => {
            const selectedGame = gamesSelected.value.findIndex((e) => e.id == game.id);
            console.log(selectedGame);
            if (selectedGame >= 0) {
                gamesSelected.value.splice(selectedGame, 1);
            } else {
                gamesSelected.value.push(game);
            }
        };

        return {
            isModalVisible,
            setIsModalVisible,
            games,
            saveNewRoulette,
            gamesSelected,
            gameSelected,
            selectGame,
        };
    },
};
</script>

<style lang="scss" scoped>
.max-h {
    max-height: 50vh;
}
</style>
